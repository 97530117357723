import React, { useEffect } from 'react';
import Hero from './Hero';
import FooterStandard from './FooterStandard';
import Process from './Process';
import FaqAccordion from './FaqAccordion';
import NavbarStandard from '../ads-pages/landing/NavbarStandard';
import { pageView } from 'utils/analytics';

const novTraining = () => {
  useEffect(() => {
    pageView();
    window.scrollTo(0, 0); // Scroll to the top on component mount
  }, []);
  return (
    <>
      <NavbarStandard />
      <Hero />
      <Process />
      <FaqAccordion />
      <FooterStandard />
    </>
  );
};

export default novTraining;
