import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    termsOfService: false,
    privacyPolicy: false
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = e => {
    const { id, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: type === 'checkbox' ? checked : value
    }));

    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      if (newErrors[id]) {
        delete newErrors[id];
      }
      return newErrors;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName)
      newErrors.firstName = 'Please Enter your first name';
    if (!formData.lastName) newErrors.lastName = 'Please Enter your last name';
    if (!formData.email) newErrors.email = 'Please Enter your email';
    if (!formData.message) newErrors.message = 'Please Enter your message';
    if (!formData.termsOfService)
      newErrors.termsOfService = 'You must agree to Terms and Conditions';
    if (!formData.privacyPolicy)
      newErrors.privacyPolicy = 'You must agree to Privacy Policy';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      setSubmitMessage('');
      try {
        const response = await fetch(
          'https://api.affluencedigitalsolutions.com/responses/',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
          }
        );
        if (response.ok) {
          setSubmitMessage("Thanks, we've received your message.");
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            termsOfService: false,
            privacyPolicy: false
          });
        } else {
          setSubmitMessage(
            'There seems to be a problem in sending your message. Please try again later.'
          );
        }
      } catch (error) {
        setSubmitMessage(
          'There seems to be a problem in sending your message. Please try again later.'
        );
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="container contact-form-container mb-5 mt-5 py-4 px-3">
      <h2 className="text-center contact-form-title">Contact Us</h2>
      <form onSubmit={handleSubmit}>
        {submitMessage && (
          <div
            className={`alert ${
              submitMessage.includes('Thanks')
                ? 'alert-success'
                : 'alert-danger'
            }`}
          >
            {submitMessage}
          </div>
        )}
        <div className="row">
          <div className="col-12 col-md-6 mb-3">
            <label htmlFor="firstName" className="form-label">
              First name
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.firstName ? 'border-danger' : ''
              }`}
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && (
              <div className="text-danger">{errors.firstName}</div>
            )}
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label htmlFor="lastName" className="form-label">
              Last name
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.lastName ? 'border-danger' : ''
              }`}
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && (
              <div className="text-danger">{errors.lastName}</div>
            )}
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className={`form-control ${errors.email ? 'border-danger' : ''}`}
            id="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <div className="text-danger">{errors.email}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            className={`form-control ${errors.message ? 'border-danger' : ''}`}
            id="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          {errors.message && (
            <div className="text-danger">{errors.message}</div>
          )}
        </div>
        <div className="mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="termsOfService"
              checked={formData.termsOfService}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="termsOfService">
              <Link to="../terms-condition" target="_blank">
                I agree to the Terms and Conditions
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
              </Link>
            </label>
            {errors.termsOfService && (
              <div className="text-danger">{errors.termsOfService}</div>
            )}
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="privacyPolicy"
              checked={formData.privacyPolicy}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="privacyPolicy">
              <Link to="../privacy-policy" target="_blank">
                I agree to the Privacy Policy
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
              </Link>
            </label>
            {errors.privacyPolicy && (
              <div className="text-danger">{errors.privacyPolicy}</div>
            )}
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-dark"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
