import paths from 'routes/paths';
import illustration1 from 'assets/img/icons/spot-illustrations/customer.jpg';
import illustration2 from 'assets/img/icons/spot-illustrations/infinity.jpg';
import illustration3 from 'assets/img/icons/spot-illustrations/team.jpg';
import illustration4 from 'assets/img/icons/spot-illustrations/growth.jpg';

const HomePageData = {
  hero: {
    heading: {
      start: 'Empower Your Future with ',
      words: ['Speed', 'Expertise']
    },
    subHeading: `Affluence Digital Solutions offers expert IT services in custom
            e-commerce, cloud solutions, production support, and QA automation.
            With global engineers and over 20 years of leadership experience, we
            deliver scalable, high-quality solutions on time, helping businesses
            achieve success.`
  },
  services: {
    title: 'Our Services',
    subHeading: `At Affluence Digital Solutions, we provide a wide range of services tailored to meet your specific business needs. From cloud solutions to managed IT services and disaster recovery, we've got you covered.`,
    serviceList: [
      {
        media: {
          icon: ['fa', 'laptop-code'],
          color: 'primary',
          className: 'fs-3'
        },
        title: 'Software Engineering',
        description:
          'We empower digital transformation through custom software development, combining our expertise in cloud platforms and cutting-edge technologies. Our team delivers high-quality, scalable solutions that adhere to industry best practices, ensuring optimal performance, security, and alignment with your business goals.',
        pathLabel: 'software-engineering'
      },
      {
        media: { icon: ['fa', 'robot'], color: 'success', className: 'fs-3' },
        title: 'Manual & Automation QA',
        description:
          'Our QA automation services enhance software quality by streamlining testing and reducing human error. Using advanced testing frameworks, we provide efficient, reliable solutions that accelerate your release cycles while upholding high-quality standards.',
        pathLabel: 'manual-and-automation-qa'
      },
      {
        media: { icon: ['fa', 'database'], color: 'danger', className: 'fs-3' },
        title: 'Data & Artificial Intelligence',
        description:
          'At Affluence Digital Solutions, we specialize in leveraging data and artificial intelligence to propel your business forward. Our tailored data and AI solutions empower your organization by enhancing decision-making and driving impactful business outcomes. ',
        pathLabel: 'data-and-artificial-intelligence'
      },
      {
        media: { icon: ['fa', 'mobile'], color: 'primary', className: 'fs-3' },
        title: 'Mobile App Development',
        description:
          'We specialize in creating innovative mobile applications that deliver exceptional user experiences. Our team develops scalable, high-performance apps tailored to your business needs, leveraging the latest technologies to ensure seamless functionality across platforms.',
        pathLabel: 'mobile-app-development'
      },
      {
        media: {
          icon: ['fa', 'hands-helping'],
          color: 'success',
          className: 'fs-3'
        },
        title: 'Production Support',
        description:
          'Our production support services ensure the smooth operation of your applications and systems. We provide proactive monitoring, troubleshooting, and timely issue resolution, minimizing downtime and maximizing performance to keep your business running seamlessly.',
        pathLabel: 'production-support'
      },
      {
        media: {
          icon: ['fa', 'user-friends'],
          color: 'danger',
          className: 'fs-3'
        },
        title: 'Building Your Own Team',
        description:
          'We help you hire, train, and develop a high-performing team tailored to your specific needs. Our expertise ensures you have the right talent in place, equipped with the skills and knowledge to drive your business forward and achieve your goals.',
        pathLabel: 'build-your-own-team'
      }
    ]
  },
  motto: {
    mottoHead: 'Ready to Elevate Your Business?',
    mottoSubHead:
      'Take the first step towards scalable, high-quality IT solutions that drive your business forward. Schedule a consultation with our experts today.'
  },
  footer: {
    missionHead: 'Our Mission',
    missionData:
      'At Affluence Digital Solutions, we prioritize quality and expertise in every project we undertake. Our commitment to continuous learning ensures that our team remains at the forefront of industry trends and technologies, allowing us to offer innovative solutions that meet our clients’ needs. We are dedicated to delivering excellence, fostering long-term relationships with our clients, and preparing them for the future with sustainable, impactful solutions.',
    footerOptions1: {
      title: 'Company',
      items: [
        { title: 'About', to: paths.aboutUs },
        { title: 'Contact Us', to: paths.contactUs },
        { title: 'Careers', to: paths.career },
        { title: 'Privacy Policy', to: paths.privacyPolicy }
      ]
    },
    footerOptions2: {
      title: 'Services',
      items: [
        { title: 'Software Engineering', to: paths.softwareEngineering },
        { title: 'Manual & Automation QA', to: paths.manualAndAutomationQa },
        {
          title: 'Data & Artificial Intelligence',
          to: paths.dataAndArtificialIntelligence
        },
        {
          title: 'Mobile App Development',
          to: paths.mobileAppDevelopment
        },
        { title: 'Production Support', to: paths.productionSupport },
        { title: 'Build Your Own Team', to: paths.buildYourOwnTeam }
      ]
    },
    addressDetails: {
      headquarter1: {
        title: 'Headquarter',
        address1: '1449/185-A T/F, KH.NO-431-432, ',
        address2: 'Street No: #3 Durga Puri Shahdara',
        address3: 'Northeast Delhi, Delhi, India 110093'
      },
      headquarter2: {
        title: 'Haryana Office',
        address1: '​5F-11, 5th Floor, Metropolis Mall,',
        address2: 'Industrial Area, Hisar, Haryana 125005'
      },
      telNo: {
        title: 'Tel.',
        value: '+91 816 847 4752'
      },
      email: 'info@affluencesolutions.com'
    }
  },
  process: {
    title: 'Our Core Values',
    subTitle:
      'Our commitment to continuous learning ensures that our team remains at the forefront of industry trends and technologies, allowing us to offer innovative solutions that meet our clients’ needs.',
    processList: [
      {
        // icon: ['far', 'lightbulb'],
        // iconText: 'Software',
        color: 'danger',
        title: 'Customer-Centric Focus',
        description:
          'We prioritize our clients’ success, ensuring their needs are met with tailored solutions that drive results.',
        image: illustration1
        // imageDark: illustration1Dark
      },
      {
        // icon: ['far', 'object-ungroup'],
        // iconText: 'BUILD',
        color: 'info',
        title: 'Excellence in Delivery',
        description:
          'We strive for the highest quality and aim to get it right the first time, delivering superior services on time.',
        image: illustration2,
        // imageDark: illustration2Dark,
        inverse: true
      },
      {
        // icon: ['far', 'paper-plane'],
        // iconText: 'DEPLOY',
        color: 'success',
        title: 'Collaboration and Teamwork',
        description:
          'Our strength lies in collaboration—both within our teams and with our clients—to achieve shared goals and sustainable success.',
        image: illustration3
        // imageDark: illustration3Dark
      },
      {
        // icon: ['far', 'paper-plane'],
        // iconText: 'DEPLOY',
        color: 'success',
        title: 'Continuous Learning and Growth',
        description:
          'We are committed to the ongoing development of our team’s skills and knowledge to stay ahead in a rapidly evolving tech landscape',
        image: illustration4,
        // imageDark: illustration3Dark,
        inverse: true
      }
    ]
  }
};

export default HomePageData;
