import React from 'react';
import PropTypes from 'prop-types';
import trainingData from 'data/ads-data/novTraining';
const Process = () => {
  return (
    <div className="container mt-5">
      <header>
        <h1 className="text-primary mb-4">{trainingData.data1.title}</h1>
        <p>
          <strong>{trainingData.data1.company}</strong> (Founded in{' '}
          {trainingData.data1.founded}), specializes in{' '}
          {trainingData.data1.specialization} with operations in{' '}
          {trainingData.data1.operations.location1} and{' '}
          {trainingData.data1.operations.location2},
          {trainingData.data1.restData}
        </p>
      </header>
      <section className="py-1">
        <h3>Overview</h3>
        <p>{trainingData.data1.overview}</p>
      </section>
      <section className="py-1">
        <h3>Training Schedule</h3>
        <div className=" p-2">
          <h4>{trainingData.data1.trainingSchedule.duration}</h4>
          <p>
            <strong>Cost:</strong> {trainingData.data1.trainingSchedule.cost}
          </p>
          <p>
            <strong>Frequency:</strong>{' '}
            {trainingData.data1.trainingSchedule.frequency}
          </p>
          <p>
            <strong>Time:</strong> {trainingData.data1.trainingSchedule.time}
          </p>
          <p>{trainingData.data1.trainingSchedule.description}</p>

          <h4>Training Topics:</h4>
          <ul>
            {trainingData.data1.trainingSchedule.topics.map((topic, index) => (
              <li key={index}>{topic}</li>
            ))}
          </ul>

          <h4>Deep Dive on the Following Topics:</h4>
          <ul>
            {trainingData.data1.trainingSchedule.deepDiveTopics.map(
              (topic, index) => (
                <li key={index}>{topic}</li>
              )
            )}
          </ul>
        </div>
      </section>

      <section className="py-1">
        <h3>Location</h3>
        <p>
          <strong>In Office:</strong>{' '}
          {trainingData.data1.location.inOffice.address},{' '}
          {trainingData.data1.location.inOffice.details}
        </p>
        <p>
          <strong>Remote:</strong> {trainingData.data1.location.remote}
        </p>
      </section>

      <section className="py-1">
        <h3>Seats</h3>
        <p>
          <strong>In Office:</strong> {trainingData.data1.seats.inOffice}
        </p>
        <p>
          <strong>Remote:</strong> {trainingData.data1.seats.remote}
        </p>
      </section>

      <section className="py-1 mb-5">
        <h3>Registration</h3>
        <p>
          <strong>Last Date to Register:</strong>{' '}
          {trainingData.data1.registration.lastDate}
        </p>
        <a
          href={trainingData.data1.registration.link}
          className="btn btn-outline-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register Here
        </a>
      </section>
    </div>
  );
};

export default Process;

Process.propTypes = {
  data: PropTypes.array
};
