// import React, { useEffect, useState } from 'react';
// import classNames from 'classnames';
// import { Link } from 'react-router-dom';
// import { Container, Nav, Navbar } from 'react-bootstrap';
// import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
// import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
// import { topNavbarBreakpoint } from 'config';
// import { useAppContext } from 'providers/AppProvider';
// import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
// import Flex from 'components/common/Flex';
// import navLogo from 'assets/img/generic/navLogo.png';

// const NavbarStandard = () => {
//   const {
//     config: { isDark }
//   } = useAppContext();
//   const [navbarCollapsed, setNavbarCollapsed] = useState(true);

//   useEffect(() => {
//     window.addEventListener('scroll', handleNavbarTransparency);
//     return () => window.removeEventListener('scroll', handleNavbarTransparency);
//   }, []);

//   return (
//     <Navbar
//       variant={isDark ? 'light' : 'dark'}
//       fixed="top"
//       expand={topNavbarBreakpoint}
//       className={classNames('navbar-standard navbar-theme', {
//         'bg-100': !navbarCollapsed && isDark,
//         'bg-dark': !navbarCollapsed && !isDark
//       })}
//     >
//       <Container>
//         <Navbar.Brand className="text-white" as={Link} to="/">
//           <img src={navLogo} alt="Logo" width="80" height="70" />
//         </Navbar.Brand>
//         <Flex alignItems="center" className="gap-2">
//           <ThemeControlDropdown dropdownClassName="d-lg-none" />
//           <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
//         </Flex>
//         <Navbar.Collapse className="scrollbar">
//           <Nav>
//             <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default NavbarStandard;

// import React, { useEffect, useState } from 'react';
// import classNames from 'classnames';
// import { Link } from 'react-router-dom';
// import { Container, Nav, Navbar } from 'react-bootstrap';
// import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
// import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
// import { topNavbarBreakpoint } from 'config';
// // import { useAppContext } from 'providers/AppProvider';
// import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
// import Flex from 'components/common/Flex';
// import navLogo from 'assets/img/generic/navLogo.png';
// // import navLogo from 'assets/img/generic/navLogo.jpg';

// const NavbarStandard = () => {
//   // const {
//   //   config: { isLight }
//   // } = useAppContext();
//   const [navbarCollapsed, setNavbarCollapsed] = useState(true);

//   useEffect(() => {
//     window.addEventListener('scroll', handleNavbarTransparency);
//     return () => window.removeEventListener('scroll', handleNavbarTransparency);
//   }, []);

//   return (
//     <Navbar
//       style={{ backgroundColor: 'light' }}
//       variant="light"
//       fixed="top"
//       expand={topNavbarBreakpoint}
//       className={classNames('navbar-standard navbar-theme', {
//         'bg-light': navbarCollapsed
//       })}
//     >
//       <Container>
//         <Navbar.Brand as={Link} to="/">
//           <img src={navLogo} alt="Logo" width="120" height="90" />
//         </Navbar.Brand>
//         <Flex alignItems="center" className="gap-2">
//           <ThemeControlDropdown dropdownClassName="d-lg-none" />
//           <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
//         </Flex>
//         <Navbar.Collapse className="scrollbar">
//           <Nav className="text-black">
//             <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default NavbarStandard;

// import React, { useEffect, useState } from 'react';
// import classNames from 'classnames';
// import { Link } from 'react-router-dom';
// import { Container, Nav, Navbar } from 'react-bootstrap';
// import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
// import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
// import { topNavbarBreakpoint } from 'config';
// import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
// import Flex from 'components/common/Flex';
// // import navLogo from 'assets/img/generic/navLogo.png';

// const NavbarStandard = () => {
//   const [navbarCollapsed, setNavbarCollapsed] = useState(true);

//   useEffect(() => {
//     window.addEventListener('scroll', handleNavbarTransparency);
//     return () => window.removeEventListener('scroll', handleNavbarTransparency);
//   }, []);

//   return (
//     <Navbar
//       variant="light"
//       fixed="top"
//       expand={topNavbarBreakpoint}
//       className={classNames('navbar-standard navbar-theme', {
//         'bg-light': navbarCollapsed
//       })}
//     >
//       <Container>
//         <Navbar.Brand as={Link} to="/">
//           {/* <img src={navLogo} alt="Logo" width="100" height="100" /> */}
//           ADS
//         </Navbar.Brand>
//         <Flex alignItems="center" className="gap-2">
//           <ThemeControlDropdown dropdownClassName="d-lg-none" />
//           <Navbar.Toggle onClick={() => setNavbarCollapsed(navbarCollapsed)} />
//         </Flex>
//         <Navbar.Collapse className="scrollbar">
//           <Nav className="text-black">
//             <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// };

// export default NavbarStandard;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
// import ThemeControlDropdown from 'components/navbar/top/ThemeControlDropdown';
import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
import Flex from 'components/common/Flex';
import { topNavbarBreakpoint } from 'config';
import navLogo from 'assets/img/generic/ads-logo.avif';
const NavbarStandard = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const updateNavElements = (addClasses, removeClasses) => {
    const navElements = document.getElementsByClassName('custom-nav-item');

    for (let i = 0; i < navElements.length; i++) {
      const el = navElements[i];
      el.classList.add(...addClasses);
      el.classList.remove(...removeClasses);
    }
  };

  const handleScroll = () => {
    const el = document.documentElement;

    if (el.scrollTop > 0) {
      setIsScrolled(true);
      updateNavElements(['text-black', 'scrolled'], ['text-white']);
    } else {
      setIsScrolled(false);
      updateNavElements(['text-white'], ['text-black', 'scrolled']);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Navbar
      variant="light"
      fixed="top"
      expand={topNavbarBreakpoint}
      className="navbar-standard navbar-theme"
      style={{
        backgroundColor: isScrolled ? 'white' : 'white',
        boxShadow: isScrolled ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
        transition: 'all 0.3s ease'
      }}
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          style={{
            color: isScrolled ? 'black' : 'white',
            transition: 'color 0.3s ease'
          }}
        >
          <img src={navLogo} alt="Logo" width="151" height="73" />
        </Navbar.Brand>
        <Flex alignItems="center" className="gap-2">
          {/* <ThemeControlDropdown dropdownClassName="d-lg-none" /> */}
          <Navbar.Toggle />
        </Flex>
        <Navbar.Collapse className="scrollbar">
          <Nav
            style={{
              color: isScrolled ? 'black' : 'white',
              transition: 'color 0.3s ease'
            }}
          >
            <NavbarTopDropDownMenus isScrolled={isScrolled} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
