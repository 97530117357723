import React, { useEffect } from 'react';
import Hero from './Hero';
import NavbarStandard from '../landing/NavbarStandard';
import Processes from './Processes';
import FooterStandard from '../landing/FooterStandard';
import { pageView } from 'utils/analytics';

const Industries = () => {
  useEffect(() => {
    pageView();
    window.scrollTo(0, 0); // Scroll to the top on component mount
  }, []);
  return (
    <>
      <NavbarStandard />
      <Hero />
      <Processes />
      <FooterStandard />
    </>
  );
};

export default Industries;
