import React from 'react';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';
import HomePageData from 'data/ads-data/landing';

const Processes = () => {
  const process = HomePageData.process;
  return (
    <Section>
      <SectionHeader title={process.title} subtitle={process.subTitle} />
      {isIterableArray(process.processList) &&
        process.processList.map((process, index) => (
          <Process key={process.color} isFirst={index === 0} {...process} />
        ))}
    </Section>
  );
};

export default Processes;
