import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import PropTypes from 'prop-types';
import Section from 'components/common/Section';
import aboutData from 'data/ads-data/about';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Cards = ({ className }) => {
  const coreValues = aboutData.coreValues || [];

  return (
    <Section className="pt-0">
      <SectionHeader title={coreValues.title} />
      {isIterableArray(coreValues.cards) && (
        <Row className="row-gap-4 pt-5">
          {coreValues.cards.map((coreValue, coreIndex) => (
            <Col xs={12} md={6} lg={6} key={coreIndex}>
              <Card className={`overflow-hidden ${className}`}>
                <Background image={coreValues.image} className="p-x1 bg-card" />
                <Card.Body className="position-relative">
                  <h5 className={`text-${coreValues.color || 'primary'}`}>
                    {coreValue.title}
                  </h5>
                  <p className="fs-10 mb-0">{coreValue.description}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Section>
  );
};

Cards.propTypes = {
  className: PropTypes.string
};

export default Cards;
