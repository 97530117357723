import React from 'react';
import { Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavItem = ({ route }) => {
  return (
    <Nav.Item>
      <Nav.Link as={Link} to={route.to} className="text-white custom-nav-item">
        {route.name}
      </Nav.Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  title: PropTypes.string,
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    active: PropTypes.bool,
    newtab: PropTypes.bool
  })
};

export default NavItem;
