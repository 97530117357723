import React from 'react';
import { Row, Col } from 'react-bootstrap';
import className from 'classnames';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';
import HomePageData from 'data/ads-data/landing';

const Services = () => {
  const data = HomePageData.services;
  return (
    <Section className="text-center bg-body-tertiary dark__bg-opacity-50 p-0 pt-4 pb-6">
      <SectionHeader title={data.title} subtitle={data.subHeading} />
      <Row className="mt-6 row-gap-6">
        {data.serviceList.map((service, index) => (
          <Col
            lg={4}
            className={className({ 'mt-6 mt-lg-0': index > 0 })}
            key={index}
          >
            <CardService {...service} />
          </Col>
        ))}
      </Row>
    </Section>
  );
};

export default Services;
