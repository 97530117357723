import React, { useEffect } from 'react';
// import React from 'react';
import Section from 'components/common/Section';
import Process from './Process';
import { isIterableArray } from 'helpers/utils';
import PropTypes from 'prop-types';

const Processes = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);
  return (
    <Section className="container p-0 mt-4 mb-5">
      {isIterableArray(data) && (
        <ul className="list-unstyled p-0">
          {data.map((item, index) => (
            <li key={index} className="mb-4 w-100">
              <div className="row align-items-center">
                <div
                  className={`col-md-6 ${
                    index % 2 === 0 ? 'order-md-1' : 'order-md-2'
                  }`}
                >
                  <Process isFirst={index === 0} {...item} />
                </div>
                <div
                  className={`col-md-6 d-flex justify-content-center ${
                    index % 2 === 0 ? 'order-md-2' : 'order-md-1'
                  }`}
                >
                  <img
                    src={item.image}
                    alt={item.alt || 'Process Image'}
                    className="img-fluid"
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Section>
  );
};

export default Processes;
Processes.propTypes = {
  data: PropTypes.array
};

// <Section>
//   {isIterableArray(data) &&
//     data.map((item, index) => (
//       <Process key={index} className="" isFirst={index === 0} {...item} />
//     ))}
// </Section>
