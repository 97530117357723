import React from 'react';
import PropTypes from 'prop-types';
import heroImg from 'assets/img/generic/industry-hero-img.jpg';
// import adsHero from 'assets/img/generic/adsHero.jpg';
import Section from 'components/common/Section';
import { Col, Row } from 'react-bootstrap';

const Hero = ({ title, subTitle }) => {
  return (
    <Section
      className="py-0 overflow-hidden"
      data-bs-theme="light"
      bgClassName="overlay-1"
      image={heroImg}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={9}
          className="pb-7 pb-xl-9 text-center text-xl-center"
        >
          <h1 className="text-white fw-light text-start ">{title}</h1>
          <p className="lead text-white opacity-75 text-start">{subTitle}</p>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired
};
