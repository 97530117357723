export const adsServices = {
  softwareEngineering: 'software-engineering',
  manualAndAutomationQa: 'manual-and-automation-qa',
  dataAndArtificialIntelligence: 'data-and-artificial-intelligence',
  mobileAppDevelopment: 'mobile-app-development',
  productionSupport: 'production-support',
  buildYourOwnTeam: 'build-your-own-team'
};
import softwareSVG from 'assets/img/illustrations/softwareSVG.png';
import backend from 'assets/img/illustrations/backendPNG.png';
import infra from 'assets/img/illustrations/infra.png';
import gcp from 'assets/img/illustrations/gcp.png';
import cloud from 'assets/img/illustrations/cloud.png';
import quality from 'assets/img/illustrations/quality.png';
import quality2 from 'assets/img/illustrations/quality (2).png';
import mobile from 'assets/img/illustrations/mobile.png';
import func from 'assets/img/illustrations/funtion.png';
import support from 'assets/img/illustrations/support.png';
import analytic from 'assets/img/illustrations/analytic.png';
import success from 'assets/img/illustrations/success.png';
import security from 'assets/img/illustrations/security.png';
import optimization from 'assets/img/illustrations/optimization.png';
import ai from 'assets/img/illustrations/ai.png';
import mad from 'assets/img/illustrations/mad.png';
import react from 'assets/img/illustrations/react.png';
import ui from 'assets/img/illustrations/ui.png';
import app from 'assets/img/illustrations/app.png';
import update from 'assets/img/illustrations/update.png';
import automation from 'assets/img/illustrations/automation.png';
import testing from 'assets/img/illustrations/testing.png';
import performance from 'assets/img/illustrations/performance.png';
import teamPerformance from 'assets/img/illustrations/teamPerformance.png';
import scalability from 'assets/img/illustrations/scalability.png';
import training from 'assets/img/illustrations/training.png';
import recruitment from 'assets/img/illustrations/recruitment.png';
import excellence from 'assets/img/illustrations/excellence.png';

const ServiceData = {
  [adsServices.softwareEngineering]: {
    heading: 'Software Engineering',
    intro:
      'At Affluence Digital Solutions, we are dedicated to the success of our clients. We don’t simply follow instructions—we focus on what’s best for your business, aligning our recommendations with your goals to deliver solutions that truly drive impact.',
    sections: [
      {
        image: softwareSVG,
        title: '1. Frontend Development',
        description:
          'Our frontend development expertise includes building high-performance web applications using React JS, Next JS, and Angular JS. We excel in:',
        points: [
          'Pixel-Perfect UI: Delivering visually accurate, stunning interfaces.',
          'Client-Side & Server-Side Rendering: Ensuring fast and smooth performance.',
          'Optimized UI Rendering: Reducing page load times for enhanced user engagement.',
          'Component-Based Design: Breaking down pages for reusability and maintainability.',
          'Security Best Practices: Applying strong security protocols at every stage.',
          'Best Coding Standards: Writing clean, scalable code that follows industry best practices.'
        ]
      },
      {
        image: backend,
        title: '2. Backend Development',
        description:
          'We specialize in backend development using Java Spring Boot, Spring Boot Reactive, Node.js, and Python, with a focus on:',
        points: [
          'Microservices Architecture: Building scalable, resilient microservices tailored to your needs.',
          'REST & GraphQL APIs: Creating secure and efficient APIs for seamless communication.',
          'Best Practices: Following solid design patterns and coding standards to ensure high performance and maintainability.'
        ]
      },
      {
        image: infra,
        title: '3. Infrastructure Setup',
        description:
          'We provide end-to-end infrastructure setup on AWS, Azure, and GCP, ensuring scalability and security:',
        points: [
          'Networking Expertise: Designing and configuring VPCs, subnets, routing tables, and peering connections for secure and efficient networking.',
          'IPv6 Implementation: To optimize cloud costs and prepare for future growth.',
          'AWS Organizations: Managing multi-environment setups to ensure separation and governance.',
          'Monitoring & Logging: Implementing CloudWatch, Flow Logs, CloudTrail, and dashboards for real-time insights and issue detection.'
        ],
        subpoints: []
      },
      {
        image: cloud,
        title: '4. Cloud Expertise Across AWS, Azure, and GCP',
        description: '',
        subpoints: [
          {
            heading: 'AWS Expertise',
            details: [
              'ALB, ECS, ECR, S3, API Gateway, Redshift, SageMaker, DynamoDB, DocumentDB, EC2, RDS, Lambda, CloudFront, Elastic Kubernetes Service (EKS), Route53, SQS, and SNS.',
              'We also optimize cloud costs by reviewing and adjusting usage based on your evolving needs.'
            ]
          },
          {
            heading: 'Azure Expertise',
            details: [
              'Azure Virtual Machines (VMs), Azure Kubernetes Service (AKS), Azure App Service, Azure Functions, API Management, Azure Blob Storage, Azure Cosmos DB, Azure SQL Database, and Azure DevOps.',
              'Networking & Security: Expertise in configuring Virtual Networks, Application Gateways, Azure Firewall, Network Security Groups (NSGs), and VPN gateways.',
              'Disaster Recovery: Implementing Azure Site Recovery (ASR) for business continuity and disaster recovery.'
            ]
          },
          {
            title: 'GCP Expertise',
            description: '',
            points: [
              'Compute Engine, Kubernetes Engine (GKE), App Engine, Cloud Functions, Cloud Storage, Cloud Spanner, BigQuery, Firebase, Cloud SQL, and Cloud Pub/Sub.',
              'Networking & Security: Expertise in configuring VPC networks, Cloud Armor, Cloud Load Balancer, Interconnects, and Cloud VPN.',
              'AI/ML Services: Using Google AI, BigQuery ML, and AutoML to harness the power of machine learning and data analytics.'
            ]
          }
        ]
      },
      {
        image: gcp,
        title: '5. Cloud Platforms',
        description:
          'Our team maximizes the potential of AWS, Azure, and GCP, ensuring cost-effective solutions and high availability for your applications.'
      },
      {
        image: quality,
        title: '6. Quality Assurance (QA)',
        description:
          'With a mix of manual and automated testing, we ensure your software performs seamlessly:',
        points: [
          'Test Automation: Using tools like Selenium and Cypress, we create automated test suites that speed up release cycles and improve quality.'
        ]
      },
      {
        image: mobile,
        title: '7. Mobile Development',
        description:
          'We build and deploy apps for iOS and Android using React Native, offering:',
        points: [
          'End-to-End Delivery: From development to app store deployment.',
          'User Tracking & Analytics: Integrating tools for visibility for monitoring and performance optimization.'
        ]
      },
      {
        image: func,
        title: '8.Non-Functional Requirements',
        description:
          'We address critical non-functional aspects to ensure your system is secure, high-performing, and resilient:',
        points: [
          'Security: Protecting your applications and infrastructure.',
          'Performance Testing: Implementing strategies using JMeter, NeoLoad, LoadRunner, Gatling, and BlazeMeter.',
          'Application Monitoring: Using tools like Dynatrace, New Relic, AppDynamics, DataDog, Splunk, ELK Stack, Sentry, and Grafana to ensure optimal performance.',
          'Application Resiliency: Managing scalability, availability, and system performance.',
          'Disaster Recovery (DR): Crafting DR strategies with the best RTO and RPO based on your business needs.'
        ],
        subpoints: []
      },
      {
        image: support,
        title: '9. Post-Launch Support and Maintenance',
        description:
          'Our commitment to your success extends beyond the launch phase:',
        points: [
          'Ongoing Production Support: We offer comprehensive support to ensure your applications operate smoothly and efficiently.',
          'Tailored Support Plans: We provide customized support options to address your specific requirements, whether you need quick fixes or long-term enhancements.',
          'At Affluence Digital Solutions, we believe in doing what’s best for your business, not just what’s asked. With your agreement, we deliver tailored solutions that drive your success and empower you to thrive in the competitive digital landscape.'
        ],
        subpoints: []
      }
    ]
  },
  [adsServices.manualAndAutomationQa]: {
    image: '',
    heading: 'Manual & Automation QA',
    intro: `At Affluence Digital Solutions, we approach Quality Assurance (QA) as a critical component of delivering enterprise-grade software.Our focus is not just on finding bugs but on ensuring that your applications are secure, scalable, and aligned with your business goals.We integrate QA deeply into every stage of your software development lifecycle to mitigate risks, ensure compliance with industry standards and provide the high-quality solutions your business demands.`,
    sections: [
      {
        image: quality2,
        title: 'Comprehensive Manual Quality Assurance',
        description: 'Meticulous Testing for Precision and Reliability',
        points: [
          'Our manual QA services focus on in-depth testing of your applications, providing insights that automated tools may overlook.',
          'We perform real-world scenario testing to evaluate usability, performance, and functionality, ensuring that your software behaves exactly as intended for every user.',
          'This rigorous process is crucial in areas where human judgment, intuition, and experience are essential to identifying potential issues.'
        ],
        subpoints: [
          {
            heading: 'Manual Testing Highlights:',
            details: [
              'User-centric testing: Simulating actual usage patterns to deliver a seamless user experience.',
              'Flexibility: Easily adapting to evolving business needs and changing requirements.',
              'Detailed insights: Comprehensive reports offering actionable recommendations for improvement.'
            ]
          }
        ]
      },
      {
        image: automation,
        title: 'Advanced Automation Quality Assurance',
        description: 'Accelerating Development with Automated Efficiency',
        points: [
          'For enterprise-scale applications, automation is key to maintaining speed and accuracy.',
          'Our Automation QA services leverage cutting-edge frameworks like Selenium, Cypress, and Postman to automate repetitive and complex testing scenarios.',
          'This allows us to test across a range of environments and use cases, reducing manual effort while improving test coverage and speeding up release cycles.'
        ],
        subpoints: [
          {
            heading: 'Automation Expertise Includes:',
            details: [
              'Automated functional testing: Ensuring business-critical functionality operates flawlessly across platforms.',
              'API testing: Using Postman to verify integration points between services.',
              'End-to-end testing: Using Cypress to ensure complete workflows are fully functional.'
            ]
          }
        ]
      },
      {
        image: testing,
        title: 'Continuous Integration and Continuous Testing',
        description: 'Embedding QA in Every Stage of Development',
        points: [
          'We integrate QA processes into your Continuous Integration (CI) and Continuous Delivery (CD) pipeline, ensuring that testing happens at every stage of development.',
          'This leads to rapid feedback on code changes, quicker identification of potential issues, and faster delivery times while maintaining high quality.',
          'With our CI/CD enabled testing, we minimize risk and enable smooth, frequent releases.'
        ],
        subpoints: [
          {
            heading: 'Key Functionalities:',
            details: [
              'Automated regression tests: Faster identification and resolution of issues as they arise.',
              'Collaboration: QA involvement at the development and testing stages to ensure a unified quality strategy.',
              'Consistency: Automation in CI/CD ensures that quality is maintained across all releases.'
            ]
          }
        ]
      },
      {
        image: quality2,
        title: 'Tailored QA Strategies for Enterprise Needs',
        description: 'Custom Solutions That Scale with Your Business',
        points: [
          'At Affluence Digital Solutions, we know that every enterprise has unique requirements.',
          'Instead of offering a one-size-fits-all approach, we work closely with your team to understand your business objectives, your tech stack, and your operational needs.',
          'Based on this understanding, we create a tailored QA strategy that is aligned with your long-term goals and ensures robust application performance, security, and scalability.'
        ],
        subpoints: []
      },
      {
        image: performance,
        title: 'Non-Functional Testing: Ensuring Performance and Resiliency',
        description: 'Going Beyond Functionality',
        points: [
          'We specialize in non-functional testing to ensure your applications are not only functional but also perform optimally under stress.',
          'From security audits to performance testing, we take care of every aspect of your software’s infrastructure.',
          'We use a range of tools like JMeter, NeoLoad, LoadRunner, and Gatling to test system resiliency, scalability, and resource usage early in the development cycle.'
        ],
        subpoints: [
          {
            heading: 'Key Focus Areas:',
            details: [
              'Security: Implementing best-in-class security measures to protect data and minimize risk.',
              'Performance testing: Using dedicated tools to optimize speed and scalability.',
              'Application monitoring: Proactive identification of issues through real-time monitoring of production environments.',
              'Resiliency & Disaster Recovery: Designing robust disaster recovery (DR) strategies with the best RTO (Recovery Time Objective) and RPO (Recovery Point Objective) for your business needs.'
            ]
          }
        ]
      },
      {
        image: teamPerformance,
        title: 'Post-Launch Production Support',
        description: 'Ensuring Stability and Continuous Improvement',
        points: [
          'After your software goes live, our involvement doesn’t end.',
          'We provide continuous post-launch support to ensure that your applications remain stable and up to date.',
          'Whether it’s applying patches, monitoring performance, or scaling infrastructure to meet growing demands, we are your dedicated partner in maintaining your software’s health over time.'
        ],
        subpoints: [
          {
            heading: 'Post-Launch Services Include:',
            details: [
              '24/7 monitoring and support: Immediate response to issues that could affect your business operations.',
              'Ongoing maintenance: Regular updates and performance tuning to keep systems optimized.',
              'Scalability & upgrades: Seamless scaling of your infrastructure as your business grows.'
            ]
          }
        ]
      },
      {
        image: success,
        title: 'Commitment to Client Success',
        description: 'We Do What’s Best for Your Business',
        points: [
          'At Affluence, we’re not just another QA provider—we’re your partner in success.',
          'We work closely with you to recommend solutions that best align with your business goals.',
          'Our priority is your long-term success, and we ensure that every decision is made with that in mind.'
        ],
        subpoints: []
      }
    ]
  },
  [adsServices.dataAndArtificialIntelligence]: {
    heading: 'Data & Artificial Intelligence',
    intro: `At Affluence Digital Solutions, we leverage the transformative potential of data and artificial intelligence (AI) to propel your business forward.In an era where data is the new currency, our specialized services empower you to harness your data for informed decision-making,enhanced operational efficiency, and the discovery of new opportunities. Here's how we enable your organization with our expertise in data and AI.`,
    sections: [
      {
        image: softwareSVG,
        title: 'Data Strategy and Consulting',
        description: 'Customized Strategies for Data-Driven Success',
        points: [
          'Understanding that each business has unique data needs, we begin with a comprehensive assessment of your existing data landscape.',
          'Our data strategy and consulting services are tailored to align with your specific business objectives, ensuring that your data efforts are not only effective but also strategically aligned.'
        ],
        subpoints: [
          {
            heading: 'Key Components of Our Data Strategy:',
            details: [
              'Data Assessment: Evaluating your current data sources and infrastructure to uncover opportunities for optimization.',
              'Roadmap Development: Crafting a detailed roadmap to guide you in effectively leveraging data, including technology adoption and process refinement.',
              'Best Practices Implementation: Establishing industry best practices to enhance data governance, quality, and compliance.'
            ]
          }
        ]
      },
      {
        image: performance,
        title: 'Data Integration and Management',
        description: 'Ensuring Seamless Data Flow Across Systems',
        points: [
          'In today’s interconnected environment, effective data integration is essential.',
          'Our data integration services ensure the seamless movement of data across various systems and platforms, providing you with a holistic view of your data landscape.'
        ],
        subpoints: [
          {
            heading: 'Data Management Solutions Include:',
            details: [
              'ETL Processes: Utilizing Extract, Transform, Load (ETL) processes to unify data from disparate sources into a centralized, accessible repository.',
              'Data Warehousing: Building robust data warehousing solutions for efficient storage and analysis of large data volumes.',
              'Real-Time Data Processing: Enabling real-time processing for timely decision-making and improved operational performance.'
            ]
          }
        ]
      },
      {
        image: analytic,
        title: 'Advanced Analytics',
        description: 'Extracting Insights from Your Data',
        points: [
          'Our advanced analytics services empower you to transform raw data into actionable insights.',
          'We utilize a blend of analytical techniques, including descriptive, predictive, and prescriptive analytics, to support your data-driven decision-making processes.'
        ],
        subpoints: []
      },
      {
        image: ai,
        title: 'Artificial Intelligence and Machine Learning',
        description: 'Innovative Solutions for Enhanced Operations',
        points: [
          'We specialize in developing AI and machine learning solutions tailored to your unique business needs.',
          'Our expert team employs the latest technologies to build intelligent applications that foster innovation and efficiency.'
        ],
        subpoints: [
          {
            heading: 'Our AI and ML Offerings Include:',
            details: [
              'Natural Language Processing (NLP): Implementing NLP solutions to improve machine understanding of human language, enhancing customer interactions and support capabilities.',
              'Computer Vision: Leveraging computer vision technologies to analyze and interpret visual data, with applications across various sectors, including healthcare and retail.',
              'Predictive Analytics: Utilizing machine learning algorithms to analyze historical data and forecast future outcomes, thereby improving strategic decision-making.'
            ]
          }
        ]
      },
      {
        image: security,
        title: 'Data Governance and Security',
        description: 'Ensuring Trust, Compliance, and Security',
        points: [
          'In today’s data-driven landscape, effective data governance and security are paramount.',
          'Our services ensure that your data remains accurate, accessible, and compliant with regulatory standards.'
        ],
        subpoints: [
          {
            heading: 'Key Aspects of Our Approach:',
            details: [
              'Data Quality Management: Implementing rigorous processes to maintain data accuracy, consistency, and reliability.',
              'Regulatory Compliance: Ensuring your data practices adhere to pertinent regulations and standards, including GDPR and HIPAA.',
              'Data Security: Utilizing advanced security measures to safeguard your data from breaches and unauthorized access.'
            ]
          }
        ]
      },
      {
        image: optimization,
        title: 'Ongoing Support and Optimization',
        description: 'Commitment to Continuous Improvement',
        points: [
          'At Affluence Digital Solutions, we provide more than just implementation; we offer continuous support and optimization to ensure your data and AI initiatives remain impactful.',
          'We monitor performance, gather feedback, and refine strategies to adapt to evolving business needs.'
        ],
        subpoints: []
      },
      {
        image: success,
        title: 'Partnership for Success',
        description: 'Your Long-Term Ally in Data and AI',
        points: [
          'Our objective is to be your long-term partner in leveraging data and AI for sustained growth and innovation.',
          'We are dedicated to ensuring your growth through customized, scalable solutions designed to meet your specific business goals.'
        ],
        subpoints: []
      }
    ]
  },
  [adsServices.mobileAppDevelopment]: {
    heading: 'Mobile App Development',
    intro: `At Affluence Digital Solutions, we leverage our expertise in mobile application development to transform your business needs into robust mobile solutions.Recognizing the shift towards mobile-first interactions, we provide specialized services that create high-performance applications tailored to your industry demands and user expectations.`,
    sections: [
      {
        image: mad,
        title: '1. Custom Mobile App Development',
        description:
          "Precision Development for Your Business Requirements. We excel in developing custom mobile applications using React Native, enabling us to create applications that precisely meet your business specifications. Whether you're looking for a native app for iOS or Android or a comprehensive cross-platform solution, our collaborative process ensures the final product aligns with your strategic goals.",
        points: [
          'We excel in developing custom mobile applications using React Native, enabling us to create applications that precisely meet your business specifications.',
          'Whether you’re looking for a native app for iOS or Android or a comprehensive cross-platform solution, our collaborative process ensures seamless performance, ensuring users enjoy fast and responsive interactions.'
        ],
        subpoints: []
      },
      {
        image: react,
        title: '2. React Native Development',
        description:
          'Streamlined Cross-Platform Solutions. Utilizing React Native, we develop mobile applications that deliver exceptional functionality across both iOS and Android platforms. This framework allows us to maintain a single codebase, significantly optimizing development time and resource allocation without compromising on quality.',
        points: [
          'Utilizing React Native, we develop mobile applications that deliver exceptional functionality across both iOS and Android platforms.',
          'This framework allows us to maintain a single codebase, significantly optimizing development time and resource allocation without compromising on quality.'
        ],
        subpoints: [
          {
            heading: 'Advantages of React Native Development:',
            details: [
              'Accelerated Time to Market: The efficiency of React Native reduces development cycles, allowing for quicker deployment.',
              'Cost Efficiency: Leveraging a unified codebase lowers development and long-term maintenance costs, providing value to your investment.',
              'High Performance: Our React Native applications achieve a near-native performance level, ensuring users have a consistent and smooth experience.'
            ]
          }
        ]
      },
      {
        image: ui,
        title: '3. UI/UX Design',
        description:
          'Enhancing User Engagement Through Thoughtful Design. Our design philosophy emphasizes the importance of creating engaging and intuitive user interfaces (UI) coupled with exceptional user experiences (UX). We aim to foster user retention and satisfaction through our design strategies.',
        points: [
          'Our design philosophy emphasizes the importance of creating engaging and intuitive user interfaces (UI) coupled with exceptional user experiences (UX).',
          'We aim to foster user retention and satisfaction through our design strategies.'
        ],
        subpoints: [
          {
            heading: 'Our Design Process Includes:',
            details: [
              'User Research and Analysis: In-depth research to understand user behavior, preferences, and pain points, ensuring our designs address real needs.',
              'Wireframing and Prototyping: We create detailed wireframes and interactive prototypes to visualize the app’s functionality and design flow, facilitating early-stage user feedback.',
              'Iterative Design Reviews: Engaging stakeholders throughout the design phase to ensure alignment with business objectives and user expectations.'
            ]
          }
        ]
      },
      {
        image: quality,
        title: '4. Quality Assurance and Testing',
        description:
          'Commitment to Excellence Through Rigorous Testing. Quality assurance is a fundamental aspect of our mobile app development strategy. Our dedicated QA team employs a meticulous testing framework to identify and rectify issues prior to launch, ensuring your app is ready for the market.',
        points: [
          'Quality assurance is a fundamental aspect of our mobile app development strategy.',
          'Our dedicated QA team employs a meticulous testing framework to identify and rectify issues prior to launch, ensuring your app is ready for the market.'
        ],
        subpoints: [
          {
            heading: 'Our Comprehensive Testing Approach:',
            details: [
              'Functional Testing: Verifying that each feature operates as intended to meet your functional requirements.',
              'Usability Testing: Conducting assessments to evaluate the overall user experience and identifying areas for enhancement.',
              'Performance Testing: Stress-testing the application under various scenarios to ensure stability, responsiveness, and scalability.'
            ]
          }
        ]
      },
      {
        image: app,
        title: '5. App Deployment and Launch',
        description: '',
        points: [],
        subpoints: [
          {
            heading: 'Guided Deployment for Smooth Launches',
            details: [
              'We manage the entire app deployment process, ensuring compliance with the submission requirements of both the Apple App Store and Google Play Store.',
              'Our experienced team facilitates a seamless launch experience, minimizing potential hurdles.'
            ]
          },
          {
            heading: 'Post-Launch Support:',
            details: [
              'Following the launch, we continue to support your app by addressing any emerging issues and implementing updates based on user feedback.'
            ]
          }
        ]
      },
      {
        image: update,
        title: '6. Ongoing Maintenance and Updates',
        description:
          'Adapting to Evolving Market Needs. The mobile application landscape is dynamic, and user expectations continue to evolve. We provide ongoing maintenance and regular updates to ensure your application remains competitive and functional.',
        points: [
          'The mobile application landscape is dynamic, and user expectations continue to evolve.',
          'We provide ongoing maintenance and regular updates to ensure your application remains competitive and functional.'
        ],
        subpoints: [
          {
            heading: 'Our Maintenance Services Include:',
            details: [
              'Proactive Updates: Implementing updates and patches to enhance security and functionality, keeping your app up-to-date.',
              'Feature Enhancements: Collaborating with you to integrate new features based on user insights and industry trends to boost engagement.',
              'Continuous Performance Monitoring: Regularly assessing app performance to identify and resolve potential issues before they affect users.'
            ]
          }
        ]
      }
    ]
  },
  [adsServices.productionSupport]: {
    heading: 'Production Support',
    intro:
      "At Affluence Digital Solutions, we recognize that the stability and reliability of your applications are paramount to your business's success. Our Production Support services are specifically designed to deliver expert technical engineers who excel in managing and supporting diverse application types.We focus on maintaining seamless operations and optimizing system performance with targeted, cost-effective solutions. Here’s how we ensure exceptional production support for our clients.",
    sections: [
      {
        image: teamPerformance,
        title: '1. Comprehensive Application Support',
        description:
          'Specialized Solutions for Your Unique Application Landscape',
        points: [
          '24/7 Monitoring: We implement round-the-clock monitoring to proactively identify and resolve issues, ensuring uninterrupted application performance.',
          'Incident Management: Our team efficiently handles incidents, minimizing downtime and facilitating rapid recovery to maintain user satisfaction.',
          'Performance Tuning: We conduct ongoing performance analysis, making necessary optimizations to enhance speed, responsiveness, and overall user experience.'
        ],
        subpoints: []
      },
      {
        image: ai,
        title: '2. Expert Technical Engineers',
        description:
          'Dedicated Professionals for Reliable Support. At Affluence Digital Solutions, our team of seasoned technical engineers is committed to delivering excellence in production support. Their extensive expertise ensures that your applications are managed by capable hands. Our Engineers’ Expertise Includes:',
        points: [
          'Diverse Technical Proficiency: Our engineers are adept in various programming languages, frameworks, and tools, enabling them to address a wide array of challenges effectively.',
          'Proactive Issue Resolution: We prioritize identifying potential problems before they escalate, implementing preventive strategies to uphold operational stability.',
          'Collaborative Partnership: Our engineers work in close coordination with your internal teams, fostering seamless communication and alignment on critical objectives.'
        ],
        subpoints: []
      },
      {
        image: softwareSVG,
        title: '3. Cost-Effective Solutions',
        description: 'Optimizing Your Investment in Production Support',
        points: [
          'Flexible Engagement Models: We offer tailored engagement options, including on-demand and dedicated support, allowing you to choose a model that aligns with your budgetary needs.',
          'Operational Cost Reduction: Outsourcing your production support to us significantly lowers the overhead costs associated with hiring and training in-house personnel.',
          'Focus on Core Activities: By entrusting your production support to our team, you can dedicate more resources to your core business functions while we ensure smooth application operations.'
        ],
        subpoints: []
      },
      {
        image: optimization,
        title: '4. Continuous Improvement and Optimization',
        description:
          'Pursuing Excellence Through Ongoing Enhancements. At Affluence Digital Solutions, we emphasize continuous improvement as a cornerstone of our production support services. Our approach includes regular assessments to identify opportunities for enhancement. Our Optimization Strategy Involves:',
        points: [
          'Feedback Loops: We establish mechanisms to gather insights from your team and end-users, allowing us to implement data-driven improvements.',
          'Transparent Reporting: We provide comprehensive reports on application performance and support activities, ensuring accountability and clarity.',
          'Adaptability to Change: As your business evolves, we tailor our support services to align with your shifting needs and objectives.'
        ],
        subpoints: []
      },
      {
        image: scalability,
        title: '5. Scalability and Flexibility',
        description:
          'Supporting Your Business Growth Our production support services are designed to be scalable and flexible, ensuring that we can meet your evolving application requirements as your business expands. How We Facilitate Growth:',
        points: [
          'Resource Scaling: We can swiftly adjust the number of engineers assigned to your project based on workload fluctuations and support demands.',
          'Technology Adaptation: Our engineers stay abreast of the latest technologies and industry trends, enabling us to implement innovative solutions that align with your growth strategies'
        ],
        subpoints: []
      }
    ]
  },
  [adsServices.buildYourOwnTeam]: {
    heading: 'Build Your Own Team',
    intro:
      'At Affluence Digital Solutions, we recognize that building a high-performing team is vital for the success of any organization. Our Build Your Own Team service is designed to help businesses create a skilled, dedicated workforce tailored to their specific needs. We take full responsibility for hiring, training, and managing your team, ensuring they are not only productive but also motivated to excel. Here’s how we streamline this process for our clients.',
    sections: [
      {
        image: recruitment,
        title: '1. Comprehensive Recruitment Process',
        description: 'Strategically Sourcing the Right Talent',
        points: [
          'We specialize in sourcing and recruiting top talent for your organization. Our recruitment process is meticulous, ensuring we find candidates who not only possess the right technical skills but also align with your company culture and values.',
          'Key Features of Our Recruitment Process:',
          'In-Depth Job Analysis: We collaborate with you to define the precise skills and qualifications needed for each role, ensuring clarity in expectations.',
          'Targeted Talent Sourcing: Utilizing various platforms and networks, we identify and attract the most suitable candidates in the industry.',
          'Rigorous Screening: Our screening process includes technical assessments, structured interviews, and reference checks to guarantee that candidates meet your standards.'
        ],
        subpoints: []
      },
      {
        image: training,
        title: '2. Tailored Training Programs',
        description: 'Empowering Your Team for Success',
        points: [
          'Once we’ve assembled your team, we provide customized training programs to equip them with the skills necessary to thrive. Our training is specifically designed around your technologies and processes, ensuring your team is prepared to make immediate contributions.',
          'Our Training Approach Includes:',
          'Structured Onboarding Programs: We create onboarding initiatives that immerse new hires in your company’s culture, tools, and workflows from day one.',
          'Focused Technical Training: Our training includes practical sessions centered on the technologies and tools relevant to your business, ensuring a smooth transition.',
          'Soft Skills Development: We emphasize the importance of soft skills, enhancing communication, collaboration, and problem-solving abilities among team members.'
        ],
        subpoints: []
      },
      {
        image: teamPerformance,
        title: '3. Ongoing Support and Mentorship',
        description: 'Guiding Your Team Towards Excellence',
        points: [
          'At Affluence Digital Solutions, we believe that mentorship is essential for team success. Our experienced professionals provide continuous support to help your team overcome challenges and develop their expertise.',
          'How We Support Your Team:',
          'Technical Guidance: Our experts are readily available to assist with technical issues and challenges, ensuring your team has the resources they need to succeed.',
          'Regular Performance Check-ins: We conduct frequent assessments to track your team’s progress, address any concerns, and adjust training as needed.',
          'Knowledge Sharing Initiatives: We foster a culture of continuous learning through workshops, seminars, and knowledge-sharing sessions.'
        ],
        subpoints: []
      },
      {
        image: excellence,
        title: '4. Building a Culture of Excellence',
        description: 'Fostering a High-Performance Environment',
        points: [
          'Creating a high-performance team involves cultivating a positive and productive team culture. We assist you in developing an environment that drives success.',
          'Our Approach to Culture Building Includes:',
          'Alignment on Performance Goals: We work with your team to establish clear performance goals and objectives, ensuring everyone is aligned with the organization’s vision.',
          'Team-Building Initiatives: We organize activities that encourage collaboration, communication, and camaraderie among team members.',
          'Recognition of Outstanding Performance: We promote a culture of recognition, encouraging and rewarding high performance to inspire motivation and commitment.'
        ],
        subpoints: []
      },
      {
        image: scalability,
        title: '5. Scalability and Flexibility',
        description: 'Adapting to Your Evolving Business Needs',
        points: [
          'As your business grows, your team’s requirements may change. Our Build Your Own Team service is designed to be scalable and flexible, allowing you to adjust team size and skills as necessary.',
          'How We Support Your Growth:',
          'Resource Scaling: We can rapidly scale your team up or down based on project demands and business requirements, ensuring you always have the right resources.',
          'Skill Adaptation: Our team continuously assesses the skills needed for your projects, providing additional training or new hires as required to keep pace with market changes.'
        ],
        subpoints: []
      }
    ]
  }
};

export default ServiceData;
