import ReactGA from 'react-ga4';

// export const trackingEvent = (eventCode, cb) => {
//   //const eventTracker = store?.getState()?.commonReducer?.eventTracker;
//   //   const selectedEvent = eventTracker
//   //     ? eventTracker?.find(event => event?.eventCode == eventCode?.eventCode)
//   //     : {};

//   // const stringValue = `$Payment processed for amount ${order.price} for vendor ${buyer} with status ${failure}`;

//   if (selectedEvent) {
//     ReactGA.event({
//       category: selectedEvent.category,
//       action: selectedEvent.action,
//       label: selectedEvent.label
//       // value: trackingPayload
//     });
//   } else {
//     ReactGA.event({
//       category: 'Missing Element',
//       action: eventCode
//     });
//   }

//   return cb;
// };

export const pageView = () => {
  ReactGA.initialize('G-TSCVGB5ECL');
  console.log(
    `path ${window.location.pathname} search: ${window.location.search}`
  );
  ReactGA.send({
    hitType: 'pageview',
    page: `${window.location.pathname}${window.location.search}`
  });
};
