import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createMarkup from 'helpers/createMarkup';

const FaqAccordionItem = ({ faq = {}, isFirst, isLast }) => {
  const { id, title, description, bullet } = faq;

  return (
    <Card
      className={classNames('shadow-none', {
        'rounded-bottom-0': isFirst,
        'border-bottom': !isLast,
        'rounded-0': !isLast && !isFirst
      })}
    >
      <Accordion.Item eventKey={id?.toString()} className="border-0">
        <Card.Header className="p-0" id={`faqAccordionHeading${id}`}>
          <Accordion.Button className=" text-decoration-none d-block w-100 py-2 px-3 border-0 rounded-0 text-start shadow-none">
            <FontAwesomeIcon
              icon="caret-right"
              className="accordion-icon me-3 mt-1"
              transform="shrink-2"
            />
            <span className="fw-medium font-sans-serif text-900">{title}</span>
          </Accordion.Button>
        </Card.Header>
        <Accordion.Body className="p-0">
          <Card.Body className="pt-2">
            <div
              className="ps-3"
              dangerouslySetInnerHTML={createMarkup(description)}
            />
            {bullet && bullet.length > 0 && (
              <ul className="ps-4">
                {bullet.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  );
};

FaqAccordionItem.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  faq: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    description: PropTypes.string,
    bullet: PropTypes.arrayOf(PropTypes.string)
  })
};

export default FaqAccordionItem;
