import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

const Process = ({ isFirst, paragraph }) => {
  return (
    <Row
      className={classNames('flex-center', {
        'mt-2': !isFirst,
        'mt-3': isFirst
      })}
    >
      <Col xs={9} className="mt-4 mt-md-0">
        <p className="d-flex flex-column gap-1 text-justify">{paragraph}</p>
      </Col>
    </Row>
  );
};

Process.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  iconText: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  color: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  aboutData: PropTypes.string.isRequired,
  descriptions: PropTypes.array,
  image: PropTypes.string.isRequired,
  imageDark: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
  children: PropTypes.node
};

export default Process;
