import React, { useEffect } from 'react';
import Hero from './Hero';
// import NavbarStandard from './NavbarStandard';
import FooterStandard from '../landing/FooterStandard';
import Account from './Account';
import privacyData from 'data/ads-data/privacy';
import NavbarStandard from '../landing/NavbarStandard';
import { pageView } from 'utils/analytics';

const Landing = () => {
  const data = privacyData;

  useEffect(() => {
    pageView();
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <>
      <NavbarStandard />
      <Hero title={data.title} />
      <Account data={data.privacy} />
      <FooterStandard />
    </>
  );
};

export default Landing;
