const trainingData = {
  hero: { title: 'How to Get a Job In IT Company?', description: '' },
  img: '',
  data1: {
    title: 'Learn How to Crack an IT Job Interview as a Fresher?',
    company: 'Affluence Digital Solutions India Private Limited',
    founded: '2018',
    specialization:
      'custom software development, data solutions, AI/ML, team building, and consulting',
    focus: 'best in the first attempt quality',
    operations: {
      location1: 'India',
      location2: 'US'
    },
    restData:
      'we deliver tailored solutions focused on best in the first attempt quality.',
    overview:
      'Only 20% of candidates progress to the second interview round, highlighting a need for change. We are committed to helping aspiring IT professionals enhance their skills through constructive feedback. Our comprehensive training program addresses key knowledge gaps, covering essential topics like tools, resume building, interview techniques, and non-functional requirements. This program equips students to excel in the competitive IT job market.',
    trainingSchedule: {
      duration: '4 Weeks Training Plan',
      cost: 'Free Training',
      frequency: '2-3 days a week',
      time: '2 hours per day',
      description:
        'This is a 4-week free training program designed to address the challenges freshers encounter when seeking employment. We will explore the reasons behind these difficulties and provide preparation strategies to help them successfully overcome obstacles and secure a job.',
      topics: [
        'Industry Landscape',
        'Challenges Faced by Freshers',
        'Standard Agile Project Life Cycle',
        'Common Team Structure',
        'Developer Roles and Responsibilities',
        'Essential Technologies and Tools for Developers',
        'Crafting an Industry-Standard Resume That Attracts Employers'
      ],
      deepDiveTopics: [
        'Why Freshers Face Challenges to Get a Job?',
        'How Companies Select the Resume for Interview?',
        'How Companies Select the Correct Match for Them?',
        'Which Technology Skills Can Help Freshers Secure a Job in an IT Company?',
        'How to Present Yourself in an Interview?',
        'How to Leave a Great Impression in the Interview?'
      ]
    },
    location: {
      inOffice: {
        address: 'Affluence Digital Solutions Hisar Office',
        details:
          '5F-11, 5th Floor, Metropolis Mall, Industrial Area, Hisar, Haryana 125005'
      },
      remote: 'Zoom for remote candidates'
    },
    seats: {
      inOffice:
        '25 seats on first-come, first-served basis (limited seats available)',
      remote: 'Unlimited seats via Zoom'
    },
    registration: {
      lastDate: '31st Oct 2024',
      link: 'https://docs.google.com/forms/d/e/1FAIpQLSeBL1n6sxvIitfhRg35POwZWso9l587D7RGmb0ev9FJHjU7KA/viewform?pli=1'
    }
  }
};

export const FaqAccordion = [
  { heading: 'Frequently asked questions', subHeading: '' },
  {
    id: 0,
    title: 'Who should take this training?',
    description:
      'This training is highly recommended for all B.Tech, MCA, and students (pursuing or passed out) from other disciplines who are facing challenges in securing a job in the IT industry. If any of the points below resonate with you, this training is designed to help you:',
    bullet: [
      'Unsure about which technology to learn to secure a job.',
      'Applied to many positions but received few callbacks.',
      'Willing to work hard yet struggling to find opportunities.',
      'Possess strong coding skills but still encountering difficulties in landing a job.',
      'Feel nervous during interviews and have not performed well.',
      'Seeking guidance on what technologies to learn to enhance job prospects.',
      'Want to know how to prepare effectively for interviews.',
      'Curious about what companies expect from candidates.',
      'Wondering why you did not succeed in previous interviews.',
      'Concerned about meeting job requirements for 1-2 years of experience after just graduating.'
    ]
  },
  {
    id: 1,
    title: 'Who will conduct this training?',
    description:
      'One of our Director will conduct your training. Here is his profile.',
    bullet: [
      'Director of Technology at Affluence Digital Solutions',
      '20 years of IT experience',
      'Specialized in product and project delivery',
      'Leading multiple projects onshore',
      'Technologies: Java, Spring, Hibernate, Microservices with Play Akka, and Spring Boot',
      'JavaScript, React, Redux, AngularJS, Handlebars, Backbone.js, etc.',
      'Node.js',
      'AWS and Microsoft Azure',
      'Oracle, DB2, MySQL',
      'MongoDB, Redis, Cassandra, Couchbase',
      'NGINX, Apache Web Server, Tomcat, WebLogic, WebSphere',
      'Oracle Endeca XM, MDEX, CAS/ITL',
      'Linux, Unix, Windows',
      'Hybris, ATG eCommerce packages',
      'Salesforce CRM'
    ]
  },
  {
    id: 2,
    title: 'How many sessions will be there in a month?',
    description:
      'This training consists of a 4-week program, featuring 2 to 3 sessions each week, with each session lasting 2 to 3 hours.'
  },
  {
    id: 3,
    title: 'Why should I do this training?',
    description: 'You should take this training to learn the following skills:',
    bullet: [
      'Industry-Relevant Skills: Learn the most in-demand tools and technologies that will boost your employability in the IT industry.',
      'Interview Preparation: Gain insights into common interview questions, expectations, and how to effectively present yourself during an interview.',
      'Resume Building: Craft a professional, standout resume tailored to industry standards and job requirements.',
      'Hands-On Guidance: Benefit from practical, real-world examples and guidance from an experienced industry professional.',
      'Address Gaps: Identify and overcome gaps in your knowledge that may have been holding you back in previous job applications.',
      'Boost Confidence: Build the confidence needed to successfully navigate the interview process and secure job offers.',
      'Non-Functional Requirements: Understand critical aspects like performance, security, and server architecture, which are highly valued by employers.',
      "Personalized Feedback: Receive personalized feedback and support, which most companies don't offer to job applicants."
    ]
  },
  {
    id: 4,
    title: 'What can I expect once this training is completed?',
    description:
      "Upon completing this training, you will gain a clear understanding of what companies look for in a fresher candidate and how to effectively prepare yourself. You'll identify areas where you may have been lacking and learn strategies to enhance your readiness for future interviews. This program will boost your confidence and equip you with the skills necessary to navigate the interview process successfully. Additionally, your resume will be polished and tailored to meet job requirements, making you stand out to potential employers.",
    bullet: []
  },
  {
    id: 5,
    title:
      'I have many questions about the latest technology trends. Will I be able to ask my questions to the trainer?',
    description:
      'Yes, it is an interactive training program. You are welcome to ask any questions to your trainer during the designated Q&A sessions.',
    bullet: []
  }
];

export default trainingData;
