const careerData = {
  hero: {
    title: 'Let’s Achieve Greatness with Technology Together!',
    subTitle:
      'Explore our career opportunities and dive into exciting projects that push the boundaries of innovation. Collaborate with industry experts and help shape the future of technology. Join us in our mission to develop transformative solutions and create a positive impact on the world.'
  },
  process: {
    title: 'Career Opportunities',
    processList: [
      {
        title: 'Shape the Future with Us!',
        value:
          'At Affluence Digital Solutions Technologies, we believe that our people are our greatest asset. We are always on the lookout for talented individuals who are passionate about technology and innovation. Join us to be part of a dynamic team that is committed to delivering cutting-edge solutions that drive digital transformation for our clients.'
      },
      {
        title: 'Why Work With Us?',
        value: [
          'Innovative Projects: Work on exciting and challenging projects that push the boundaries of technology and creativity.',
          'Collaborative Environment: Collaborate with a diverse team of industry experts who are dedicated to sharing knowledge and fostering growth.',
          'Continuous Learning: We prioritize professional development and offer opportunities for continuous learning to help you stay ahead in your career.',
          'Impactful Work: Contribute to projects that make a positive difference in the world, creating solutions that enhance business efficiency and user experience.'
        ]
      },
      {
        title: 'Current Openings',
        value: [
          'Software Development',
          'Cloud Solutions',
          'Quality Assurance',
          'Project Management',
          'Data and AI'
        ]
      },
      {
        title: 'Join Us!',
        value:
          'If you’re ready to take your career to the next level and make a meaningful impact, we’d love to hear from you! Explore our open positions and apply today by sending your resume to hr@affluencesolutions.com. Join Affluence Digital Solutions Technologies in shaping the future of technology together!'
      }
    ]
  }
};

export default careerData;
