import React from 'react';
import PropTypes from 'prop-types';
import createMarkup from 'helpers/createMarkup';
import Section from 'components/common/Section';
// import IconGroup from 'components/common/icon/IconGroup';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { blogPostList, menuList1, menuList2 } from 'data/footer';
// import { bgWhiteIcons } from 'data/socialIcons';
// import { version } from 'config';
import HomePageData from 'data/ads-data/landing';

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const handleClick = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
};

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="link-600" to={to} onClick={handleClick}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-9 mb-0">
          <Link className="link-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterAddress = () => {
  const addressDetails = {
    headquarter1: {
      title: 'Headquarter',
      address1: '1449/185-A T/F, KH.NO-431-432, ',
      address2: 'Street No: #3 Durga Puri Shahdara',
      address3: 'Northeast Delhi, Delhi, India 110093'
    },
    headquarter2: {
      title: 'Haryana Office',
      address1: '​5F-11, 5th Floor, Metropolis Mall,',
      address2: 'Industrial Area, Hisar, Haryana 125005'
    },
    telNo: {
      title: 'Tel.',
      value: '+91 816 847 4752'
    },
    email: 'info@affluencesolutions.com'
  };

  return (
    <div className="fs-10 d-flex flex-column gap-4">
      <div>
        <div className="fw-bold fs-9">{addressDetails.headquarter1.title}</div>
        <div>{addressDetails.headquarter1.address1}</div>
        <div>{addressDetails.headquarter1.address2}</div>
        <div>{addressDetails.headquarter1.address3}</div>
      </div>

      <div>
        <div className="fw-bold fs-9">{addressDetails.headquarter2.title}</div>
        <div>{addressDetails.headquarter2.address1}</div>
        <div>{addressDetails.headquarter2.address2}</div>
      </div>

      <div>
        <div className="fw-bold fs-9">
          {addressDetails.telNo.title} {addressDetails.telNo.value}
        </div>
        <div className="fw-bold fs-9">{addressDetails.email}</div>
      </div>
    </div>
  );
};

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const footer = HomePageData.footer;
  return (
    <>
      <Section bg="dark" className="pt-8 pb-4" data-bs-theme="light">
        <div
          className="position-absolute btn-back-to-top cursor-pointer bg-dark"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />
        </div>
        <Row>
          <Col lg={4}>
            <FooterTitle>{footer.missionHead}</FooterTitle>
            <p className="text-600">{footer.missionData}</p>
            {/* <IconGroup className="mt-4" icons={bgWhiteIcons} /> */}
          </Col>
          <Col className="ps-lg-6 ps-xl-8">
            <Row className="mt-5 mt-lg-0">
              {footer.footerOptions1 && (
                <Col xs={6} md={3}>
                  <FooterTitle>{footer.footerOptions1.title}</FooterTitle>
                  <FooterList list={footer.footerOptions1.items} />
                </Col>
              )}
              {footer.footerOptions2 && (
                <Col xs={6} md={4}>
                  <FooterTitle>{footer.footerOptions2.title}</FooterTitle>
                  <FooterList list={footer.footerOptions2.items} />
                </Col>
              )}
              <Col className="mt-5 mt-md-0">
                <FooterTitle>{footer.footerOptions1.title}</FooterTitle>
                <FooterAddress></FooterAddress>
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>

      {/* <section className="bg-dark py-0 text-center fs-10" data-bs-theme="light">
        <hr className="my-0 border-600 opacity-25" />
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                Thank you for creating with Falcon{' '}
                <span className="d-none d-sm-inline-block">| </span>
                <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
                <a
                  className="text-white opacity-85"
                  href="https://themewagon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ThemeWagon
                </a>
              </p>
            </Col>
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">v{version}</p>
            </Col>
          </Row>
        </div>
      </section> */}
    </>
  );
};

export default FooterStandard;
