import React from 'react';
import Section from 'components/common/Section';
import Process from './Process';
import { isIterableArray } from 'helpers/utils';
import aboutData from 'data/ads-data/about';

const Processes = () => {
  const process = aboutData.process;
  return (
    <Section className="py-5">
      {isIterableArray(process.processList) &&
        process.processList.map((paragraph, index) => (
          <Process key={index} isFirst={index === 0} paragraph={paragraph} />
        ))}
    </Section>
  );
};

export default Processes;
