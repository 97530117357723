import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';

const Process = ({ icon, iconText, isFirst, color, title, descriptions }) => {
  // console.log(color, descriptions);
  return (
    <Row
      className={classNames('flex-center', {
        'mt-2': !isFirst,
        'mt-3': isFirst
      })}
    >
      <Col xs={9} className="mt-4 mt-md-0">
        <h5 className={`text-${color}`}>
          <FontAwesomeIcon icon={icon} className="me-2" />
          {iconText}
        </h5>
        <h3>{title}</h3>
        <ul className="d-flex flex-column gap-1">
          {descriptions?.map((description, processIndex) => (
            <li key={processIndex}>
              {description.value}
              {description.children && (
                <ul>
                  <li>
                    {description.children.map((child, childIndex) => (
                      <li key={childIndex}>{child}</li>
                    ))}
                  </li>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

Process.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  iconText: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.array,
  image: PropTypes.string.isRequired,
  imageDark: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
  children: PropTypes.node
};

export default Process;
