import React, { useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card } from 'react-bootstrap';
import FaqAccordionItem from './FaqAccordionItem';
import { FaqAccordion as faqsData } from 'data/ads-data/novTraining';

const FaqAccordion = () => {
  const [faqs] = useState(faqsData);

  const data = faqs[0] || 'Frequently Asked Questions';
  return (
    <>
      <PageHeader
        title={data.heading}
        description={data.subHeading}
        className="mb-3"
      />
      <Card>
        <Card.Body className="mb-7">
          <Accordion
            id="accordionFaq"
            className="border rounded overflow-hidden"
          >
            {faqs.map((faq, index) => (
              <FaqAccordionItem
                key={faq.id}
                faq={faq}
                isFirst={index === 1}
                isLast={index === faqs.length - 1}
              />
            ))}
          </Accordion>
        </Card.Body>
      </Card>
    </>
  );
};

export default FaqAccordion;
