import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useAppContext } from 'providers/AppProvider';
import career from 'assets/img/generic/career.jpeg';
import Section from 'components/common/Section';
// import { Button, Col, Row } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import careerData from 'data/ads-data/career';

const Hero = () => {
  // const {
  //   config: { isDark, isRTL }
  // } = useAppContext();

  const data = careerData;

  return (
    <Section
      className="py-0 overflow-hidden "
      bgClassName="overlay-1"
      data-bs-theme="light"
      image={career}
      position="center"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={6}
          lg={6}
          xl={11}
          className="pb-7 pb-xl-11 text-start text-xl-start"
        >
          <h1 className="text-white fw-light">{data.hero.title}</h1>
          <p className="lead text-white opacity-75">{data.hero.subTitle}</p>
          {/* <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-9 py-2"
            to="#!"
          >
            Learn More
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button> */}
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
