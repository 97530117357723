import React from 'react';
import about from 'assets/img/generic/about.jpg';
import Section from 'components/common/Section';
import { Col, Row } from 'react-bootstrap';
import contactUsData from 'data/ads-data/contact';

const Hero = () => {
  // const {
  //   config: { isDark, isRTL }
  // } = useAppContext();

  const data = contactUsData;

  return (
    <Section
      className="py-0 overflow-hidden "
      data-bs-theme="light"
      image={about}
      position=" center"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={6}
          lg={6}
          xl={11}
          className="pb-7 pb-xl-11 text-start text-xl-start"
        >
          <h1 className="text-white fw-light text-center">{data.title}</h1>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
