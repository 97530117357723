const termsData = {
  HeroTtile: 'Terms & Conditions',
  description:
    'The use of any product, service or feature (the "Materials") available through the internet websites accessible at affluence digital solutions.com by any user of the Website (“User” or "You" or "Your" hereafter) shall be governed by the following terms of use. The following terms and conditions will be deemed to have been accepted by the User on usage of the Website. You are requested to read them carefully before you use the services of this site.',
  descriptionChild: [
    {
      child: [
        'The term User shall refer to the user who is browsing the Website.',
        'This Website is provided by affluence digital solutions Limited.By using the Website or downloading Materials from the Website, You hereby agree to abide by the terms and conditions set forth in this Terms of Use. In the event of You not agreeing to these terms and conditions, You are requested by affluence digital solutions not to use the Website or download Materials from the Website. If there is a conflict between the Terms of Use and terms of use posted for a specific area of the Website, the latter shall have precedence with respect to your use of that area of the Website.',
        'This Website, including all Materials present (excluding any applicable third party materials), is the property of affluence digital solutions and affluence digital solutions retains all rights, title or interest, including all intellectual property laws in such Materials...',
        'affluence digital solutions has business relationships with thousands of customers, suppliers, governments, and others. For convenience and simplicity, words like joint venture, partnership, and partner are used to indicate business relationships involving common activities and interests, and those words may not indicate precise legal relationships.'
      ]
    },

    {
      title: 'LIMITED LICENSE:',
      description:
        'This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Subject to the terms and conditions set forth in these Terms of Use, affluence digital solutions grants You a non-exclusive, non-transferable, limited copyright license to access, and display this Website and the Materials thereon provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact. You agree not to interrupt or attempt to interrupt the operation of the Website in any manner.. You shall not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products or services obtained from this Website. Except for the limited permission in the preceding paragraph, affluence digital solutions does not grant you any express or implied rights or licenses under any patents, trademarks, copyrights, or other proprietary or intellectual property rights. You may not mirror any of the content from this site on another Web site or in any other media. Any software and other materials that are made available for downloading, access, or other use from this site with their own license terms will be governed by such terms, conditions, and notices. Your failure to comply with such terms or any of the terms on this site will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession, custody or control.'
    },
    {
      title: 'BLOGS:',
      description:
        'This is to inform and clarify that individuals (including but not limited to employees of affluence digital solutions and referred to as “Individuals” hereafter) may contribute articles and opinions on this Website entirely at the sole discretion of affluence digital solutions, in the form of “blogs”, as such term is generally understood. You hereby acknowledge and agree that these blogs constitute the opinion of the Individuals in their personal capacity, and may not represent official positions of affluence digital solutions in any manner. affluence digital solutions retains all copyright to these blogs and grants the same license as mentioned under section “Limited License” hereinabove.You may be permitted to post comments and feedback to these blogs. By doing so, You expressly agree and acknowledge to abide by the following:',
      child: [
        'You shall not defame, disrepute, abuse, harass or threaten affluence digital solutions or any third party, or otherwise violate the legal rights of affluence digital solutions or any third party.',
        'You shall not contribute any content or take any action that may in any manner adversely affect the reputation of affluence digital solutions, or that is otherwise detrimental to affluence digital solutions.',
        'You shall not in any manner publish or post any inappropriate, defamatory, infringing, obscene, racist, terrorist, politically slanted, indecent or unlawful topic, name, material or information.',
        'You shall not use profane and objectionable language or abbreviations. You shall not use any character(s) as a substitute for objectionable language.',
        'You shall not in any manner reveal confidential or proprietary information of any third party. Specifically, You shall not post any material for which You do not have requisite and applicable right(s) under law.',
        'You shall not conduct any contests or publish or propagate any forwards.',
        'You shall not defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity but not limited to the foregoing) of any other party including affluence digital solutions.',
        'You shall not publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, obscene, indecent or unlawful topic, name, material or information.',
        'You shall not upload or otherwise make available, files that contain images, photographs, software or other material protected by intellectual property laws, including, by way of example, and not as limitation, copyright or trademark laws (or by rights of privacy or publicity) unless You own or control the rights thereto or have received all necessary consent to do the same.',
        "You shall not upload files that contain viruses, trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another's computer or property of another.",
        'You shall not advertise or offer to sell or buy any goods or services for any business purpose',
        'You shall not download any file posted that You know, or reasonably should know, cannot be legally reproduced, displayed, performed, and/or distributed in such manner.',
        'You shall not falsify or delete any copyright management information, such as author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.',
        'You shall not create a false identity for the purpose of misleading others.',
        'You shall not in any way deface or vandalize this Website, or prevent or restrict others from using this Website.',
        'You shall indemnify and hold harmless affluence digital solutions from any claims and loss incurred by affluence digital solutions as a result of Your violation of these Terms of Use.',
        'You acknowledge that affluence digital solutions may, at its sole discretion, monitor, remove or edit any content that You contribute. affluence digital solutions may also pursue remedies available to it under law for any violation of these terms and conditions.',
        'You shall ensure that any personal data of other individuals is shared by you only after seeking consent from the person(s) to whom the personal data belongs.'
      ]
    },
    {
      title: 'THIRD PARTY CONTENT:',
      description:
        'The Website makes information of third parties available, including articles, analyst reports, news reports, tools to facilitate calculation, company information and data about financial markets, including any regulatory authority and other financial markets and other data from external sources (the "Third Party Content"). You acknowledge and agree that the Third Party Content is not created or endorsed by affluence digital solutions..',
      child: [
        'The Website may provides links to web sites and access to content, products and services from third parties, including users, advertisers, affiliates and sponsors of the Website i.e Third Party Content. You agree that affluence digital solutions is not responsible for the availability of, and content provided on, third party web sites. The User is requested to peruse the policies posted by other web sites regarding privacy and other topics before use. Any personal data processing by such third parties shall be governed by their respective privacy policies.',
        'Affluence digital solutions is not responsible for Third Party Content accessible through the Website, including opinions, advice, statements and advertisements, and User shall bear all risks associated with the use of such content including any intellectual property infringement claim by third parties. affluence digital solutions is not responsible for any loss or damage of any sort User may incur from dealing with any third party or Third Party Content.'
      ]
    },
    {
      title: 'Confidential information',
      description:
        'Affluence digital solutions does not want to receive confidential or proprietary information from you through our Web site. Please note that any information or material sent to affluence digital solutions will be deemed NOT to be confidential. By sending affluence digital solutions any information or material, you grant affluence digital solutions an unrestricted, irrevocable license to copy, reproduce, publish, upload, post, transmit, distribute, publicly display, perform, modify, create derivative works from, and otherwise freely use, those materials or information. You also agree that affluence digital solutions is free to use any ideas, concepts, know-how, or techniques that you send us for any purpose. However, we will not release your name or otherwise publicize the fact that you submitted materials or other information to us unless: (a) we obtain your permission to use your name; or (b) we first notify you that the materials or other information you submit to a particular part of this site will be published or otherwise used with your name on it; or (c) we are required to do so by law. Personally-identifiable information that you submit to affluence digital solutions for the purpose of receiving products or services will be handled in accordance with our privacy policies. Please refer to ADS Privacy Statement for more information regarding our privacy practices.',
      child: []
    },
    {
      title: 'NO WARRANTIES:',
      description:
        'This website, the information and materials on the site, and any software made available on the Website, are provided "as is" without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose. There is no warranty of any kind, express or implied, regarding third party content. In spite of affluencedigitalsolutions best endeavors, there is no warranty on behalf of affluence digital solutions that this Website will be free of any computer viruses. affluence digital solutions shall have no responsibility for any damage to User’s computer system or loss of data that results from the download of any content, materials, information from Website. Some jurisdictions do not allow for the exclusion of implied warranties, so the above exclusions may not apply to you. affluence digital solutions makes no warranty that: (a) the Website will meet your requirements; (b) Website will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of the website or any services offered through the Website will be accurate or reliable.',
      child: []
    },
    {
      title: 'LIMITATION OF DAMAGES:',
      description:
        'TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL AFFLUNCE DIGITAL SOLUTIONS BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER RELATED TO OR ARISING FROM THIS WEB SITE OR ANY USE OF THIS WEB SITE, OR OF ANY SITE OR RESOURCE LINKED TO, REFERENCED, OR ACCESSED THROUGH THIS WEB SITE, OR IN THE PRODUCTS ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS OR OTHER INACCURACIES IN THE PRODUCT OR INTERPRETATIONS THEREOF OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF affluence digital solutions IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT, OR ANY OTHER LEGAL THEORIES.',
      child: []
    },
    {
      title: 'DISCLAIMER:',
      description:
        'The website may contain inaccuracies and typographical and clerical errors. affluence digital solutions expressly disclaims any obligation(s) to update this website or any of the materials on this website. affluence digital solutions does not warrant the accuracy , adequacy or completeness of the materials or the reliability of any advice, opinion, statement or other information displayed or distributed through the Website. You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. affluence digital solutions reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Website. affluence digital solutions may make any other changes to the Website, the materials and the products, programs, services or prices (if any) described in the Website at any time without notice. This Website is for informational purposes only and should not be construed as technical advice of any manner. If any term in this Terms of Use is found by competent judicial authority to be unenforceable in any respect, the validity of the remainder of this Terms of Use will be unaffected, provided that such unenforceability does not materially affect the parties’ rights under this Terms of Use.'
    }
  ]
};

export default termsData;
