import React, { useEffect } from 'react';
import NavbarStandard from '../landing/NavbarStandard';
import FooterStandard from '../landing/FooterStandard';
import Terms from './terms';
import Hero from './Hero';
import termsData from 'data/ads-data/terms';
import { pageView } from 'utils/analytics';

const TermsLanding = () => {
  const data = termsData;

  useEffect(() => {
    pageView();
  }, [data]);

  return (
    <>
      <NavbarStandard />
      <Hero data={data.HeroTtile} />
      <Terms data={data} />
      <FooterStandard />
    </>
  );
};

export default TermsLanding;
