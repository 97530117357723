const industryData = {
  hero: {
    title: 'Expert IT Solutions for Your Business'
  },
  process: {
    title: 'Industries We Serve',
    subTitle:
      'At Affluence Digital Solutions Technologies, we specialize in delivering custom software development solutions tailored to meet the unique needs of various industries. Our expertise spans across:',
    processList: [
      {
        title: '1. Retail',
        descriptions: [
          {
            value:
              'Enhanced Customer Experience: We empower retail businesses to provide seamless shopping experiences through:',
            children: [
              'Innovative eCommerce platforms with intuitive user interfaces.',
              'Personalized marketing tools for targeted promotions and customer engagement.'
            ]
          },
          {
            value:
              'Operational Efficiency: Our development services help retailers streamline operations by:',
            children: [
              'Implementing inventory management systems to optimize stock levels.',
              'Utilizing data analytics for informed decision-making and demand forecasting.'
            ]
          },
          {
            value:
              'Mobile Solutions: We create mobile applications that enhance customer interactions and loyalty programs.'
          }
        ]
      },
      {
        title: '2. Healthcare',
        descriptions: [
          {
            value:
              'Patient Care Improvement: We focus on solutions that enhance patient care through:',
            children: [
              'Secure health management systems that facilitate better communication between providers and patients.',
              'Telemedicine applications that enable remote consultations and monitoring.'
            ]
          },
          {
            value:
              'Compliance and Security: Our solutions ensure adherence to industry regulations, including:',
            children: [
              'Development of Electronic Health Record (EHR) platforms that prioritize patient data security.',
              'Implementation of HIPAA-compliant systems for data handling and storage'
            ]
          },
          {
            value:
              'Operational Streamlining: We create tools for appointment scheduling, patient management, and billing systems to improve operational efficiency.'
          }
        ]
      },
      {
        title: '3. Insurance',
        descriptions: [
          {
            value:
              'Automation and Efficiency: In the insurance industry, we focus on automating processes to enhance efficiency:',
            children: [
              'Custom applications for claims processing that reduce turnaround times.',
              'Tools for customer service that streamline communication and issue resolution.'
            ]
          },
          {
            value:
              'Risk Assessment and Management: Our solutions support insurers in:',
            children: [
              'Developing data-driven risk assessment tools for underwriting.',
              'Creating dashboards for real-time analytics and reporting.'
            ]
          },
          {
            value:
              'Customer-Centric Solutions: We build platforms that improve customer experience, including:',
            children: [
              'Self-service portals for policy management and claims tracking.',
              'Mobile applications for easy access to services and information.'
            ]
          }
        ]
      },
      {
        title: '4. Hospitality',
        descriptions: [
          {
            value:
              'Guest Experience Enhancement: We provide technology solutions that elevate guest experiences:',
            children: [
              'Reservation systems that simplify booking processes.',
              'Customer Relationship Management (CRM) tools for personalized service.'
            ]
          },
          {
            value:
              'Operational Management: Our solutions help hospitality businesses manage operations more efficiently:',
            children: [
              'Development of property management systems (PMS) for streamlined hotel operations.',
              'Mobile applications that facilitate guest interactions and feedback collection.'
            ]
          },
          {
            value:
              'Marketing and Loyalty Programs: We create marketing platforms that support targeted promotions and customer loyalty initiatives.'
          }
        ]
      },
      {
        title: '5.  Public Sector',
        descriptions: [
          {
            value:
              'Efficiency and Transparency: Our work with public sector organizations focuses on increasing efficiency and transparency:',
            children: [
              'Development of data management systems that facilitate informed decision-making.',
              'Solutions for public engagement that enhance citizen interaction and feedback.'
            ]
          },
          {
            value:
              'Service Delivery Improvement: We create platforms that help government agencies provide better services:',
            children: [
              'Tools for managing permits, licenses, and public records.',
              'Mobile applications that allow citizens to access services and information easily.'
            ]
          },
          {
            value:
              'Compliance and Security: Our solutions ensure adherence to regulations and standards, enhancing the integrity and security of public data.'
          }
        ]
      }
    ]
  }
};

export default industryData;
