import React from 'react';
import { Row, Col } from 'react-bootstrap';
import bg2 from 'assets/img/generic/bg-2.jpg';
// import mottoBg from 'assets/img/generic/mottoBg.jpg';
import Section from 'components/common/Section';
import HomePageData from 'data/ads-data/landing';

const Cta = () => {
  const motto = HomePageData.motto;
  return (
    <Section
      overlay
      image={bg2}
      position="center top"
      className="bg-dark"
      data-bs-theme="light"
    >
      <Row className="justify-content-center text-center">
        <Col lg={12}>
          <h1 className="text-white">{motto.mottoHead}</h1>
          <p className="fs-6 fs-sm-5 text-white">{motto.mottoSubHead}</p>
          {/* <Button
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-9 py-2"
          >
            Free Consultation
          </Button> */}
        </Col>
      </Row>
    </Section>
  );
};

export default Cta;
