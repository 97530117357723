import React from 'react';
import PropTypes from 'prop-types';
// import { Card } from 'react-bootstrap';

const Account = ({ data }) => {
  return (
    <div className="container my-4 px-4 px-lg-5 text-justify">
      {data.map((item, index) => (
        <div key={index} className="mb-5">
          <h3 className="text-start text-md-start mb-3">{item.title}</h3>
          <p>{item.description}</p>
          {item.child && (
            <ul className="mt-3 ps-4">
              {item.child.map((childItem, childIndex) => (
                <li key={childIndex} className="mb-2">
                  {childItem}
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

Account.propTypes = {
  data: PropTypes.array
};

export default Account;
