import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';

const Process = ({ isFirst, title, description, subpoints, points }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Row
      className={classNames('flex-center', {
        'mt-2': !isFirst,
        'mt-3': isFirst
      })}
    >
      <Col xs={12} className="mt-4 mt-md-0 text-justify">
        <h3>{title}</h3>
        <p>{description}</p>
        {isIterableArray(points) && (
          <ul className="d-flex flex-column gap-1 text-justify">
            {points?.map((point, idx) => (
              <li key={idx}>{point}</li>
            ))}
          </ul>
        )}

        {isIterableArray(subpoints) &&
          subpoints?.map((subpoint, idx) => (
            <div key={idx}>
              <h5 className="my-3">{subpoint.heading}</h5>
              <ul>
                {subpoint?.details?.map((detail, subIdx) => (
                  <li key={subIdx}>{detail}</li>
                ))}
              </ul>
            </div>
          ))}
      </Col>
    </Row>
  );
};

Process.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  iconText: PropTypes.string,
  isFirst: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  points: PropTypes.array,
  subpoints: PropTypes.array,
  image: PropTypes.object,
  imageDark: PropTypes.string,
  inverse: PropTypes.bool,
  children: PropTypes.node
};

export default Process;
