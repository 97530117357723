import React from 'react';
// import { useAppContext } from 'providers/AppProvider';
import heroImg from 'assets/img/generic/industry-hero-img.jpg';
import Section from 'components/common/Section';
import { Col, Row } from 'react-bootstrap';
import propTypes from 'prop-types';

const Hero = ({ title }) => {
  // const {
  //   config: { isDark, isRTL }
  // } = useAppContext();

  return (
    <>
      <Section
        className="py-0 overflow-hidden"
        data-bs-theme="light"
        bgClassName="overlay-1"
        image={heroImg}
        position="center bottom"
        overlay
      >
        <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
          <Col
            md={11}
            lg={8}
            xl={12}
            className="pb-7 pb-xl-9 text-center text-xl-center"
          >
            <h1 className="text-white fw-light">{title}</h1>
            {/* <p className="lead text-white opacity-75">{data.subHeading}</p> */}
          </Col>
        </Row>
      </Section>
    </>
  );
};

Hero.propTypes = {
  title: propTypes.string.isRequired
};
export default Hero;
