import Section from 'components/common/Section';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ServiceHeading = ({ title, subTitle }) => {
  return (
    <>
      <Section
        className="py-0 overflow-hidden"
        // data-bs-theme="light"
        // bgClassName="overlay-1"
        // image={adsHero}
        position="center bottom"
        // overlay
      >
        <Row className="justify-content-center align-items-center mt-4 pb-0 pb-lg-9 pb-xl-0">
          <Col
            md={11}
            lg={8}
            xl={9}
            className=" pb-xl- text-center text-xl-center"
          >
            <h1 className="  text-start ">{title}</h1>
            <p className="lead text-start">{subTitle}</p>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default ServiceHeading;
ServiceHeading.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.String
};
