import corner2 from 'assets/img/illustrations/corner-2.png';

const aboutData = {
  process: {
    title: 'About Us',
    aboutData: '',
    processList: [
      'Affluence Digital Solutions, established in 2018, focuses on empowering businesses through comprehensive digital transformation. We specialize in delivering timely and effective solutions in custom e-commerce development, cloud services, production support, and QA automation. Our commitment to innovation allows us to provide high-quality services that drive real value for our clients, ensuring they stay competitive in an ever-evolving market.',
      'Our team is the backbone of our success, combining technical expertise with passion and adaptability. With over 20 years of leadership experience, our professionals possess the skills necessary to tackle complex challenges and deliver exceptional results. We have successfully partnered with various organizations, enabling them to enhance their operational efficiency and achieve their business goals through tailored solutions.',
      'At Affluence Digital Solutions, we prioritize quality and expertise in every project we undertake. Our commitment to continuous learning ensures that our team remains at the forefront of industry trends and technologies, allowing us to offer innovative solutions that meet our clients’ needs. We are dedicated to delivering excellence, fostering long-term relationships with our clients, and preparing them for the future with sustainable, impactful solutions.'
    ]
  },
  coreValues: {
    title: 'Our Values',
    image: corner2,
    color: 'info',
    cards: [
      {
        title: 'Customer-Centric Focus',
        description:
          'We prioritize our clients’ success, ensuring their needs are met with tailored solutions that drive results.'
      },
      {
        title: 'Excellence in Delivery',
        description:
          'We strive for the highest quality and aim to get it right the first time, delivering superior services on time.'
      },
      {
        title: 'Innovation and Adaptability',
        description:
          'We embrace the latest technologies and adapt quickly to industry changes, always seeking new ways to improve and innovate.'
      },
      {
        title: 'Integrity and Transparency',
        description:
          'We maintain honest, open communication with our clients, ensuring trust and accountability in all our partnerships.'
      },
      {
        title: 'Collaboration and Teamwork',
        description:
          'Our strength lies in collaboration—both within our teams and with our clients—to achieve shared goals and sustainable success.'
      },
      {
        title: 'Continuous Learning and Growth',
        description:
          'We are committed to the ongoing development of our team’s skills and knowledge to stay ahead in a rapidly evolving tech landscape.'
      }
    ]
  }
};

export default aboutData;
