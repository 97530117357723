import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavbarStandard from '../landing/NavbarStandard';
import ServiceData from 'data/ads-data/service';
import FooterStandard from '../landing/FooterStandard';
import Hero from './Hero';
import Processes from './Processes';
import { pageView } from '../../../../utils/analytics';
import ServiceHeading from './ServiceHeading';

const Services = () => {
  const params = useParams();
  const data = ServiceData[params.service] || {};
  useEffect(() => {
    pageView();
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavbarStandard />
      {/* <Hero title={data.heading} subTitle={data.intro} /> */}
      <Hero />
      <ServiceHeading title={data.heading} subTitle={data.intro} />
      <Processes data={data.sections} />
      <FooterStandard />
    </>
  );
};

export default Services;
