import React, { useEffect } from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Processes from './Processes';
import Services from './Services';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import ContactForm from '../contact-us/ContactForm';
import { pageView } from '../../../../utils/analytics';

const Landing = () => {
  useEffect(() => {
    pageView();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavbarStandard />
      <Hero />
      <Services />
      <Processes />
      <Cta />
      <ContactForm />
      <FooterStandard />
    </>
  );
};

export default Landing;
