import React from 'react';
import PropTypes from 'prop-types';

const terms = ({ data }) => {
  return (
    <div className="container-fluid px-4 px-md-6 px-lg-11">
      <section className="text-justify py-4 py-lg-4 py-md-4">
        <p>{data.description}</p>
        {data.descriptionChild.map((section, index) => (
          <div key={index} className="mt-4">
            {section.title && (
              <h3 className="my-3 text-center text-md-start">
                {section.title}
              </h3>
            )}
            <p>{section.description}</p>

            {section.child && section.child.length > 0 && (
              <ul className="mt-3 ps-4">
                {section.child.map((item, childIndex) => (
                  <li key={childIndex} className="mb-2">
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </section>
    </div>
  );
};

// Define PropTypes for validation
terms.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    descriptionChild: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        child: PropTypes.arrayOf(PropTypes.string)
      })
    ).isRequired
  }).isRequired
};

export default terms;
