import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'providers/AppProvider';
import bg1 from 'assets/img/generic/custom-hero-bg.jpg';
// import adsHero from 'assets/img/generic/adsHero.jpg';
import Section from 'components/common/Section';
import { Col, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import HomePageData from 'data/ads-data/landing';

const Hero = () => {
  const {
    config: { isRTL }
  } = useAppContext();

  const data = HomePageData.hero;
  return (
    // <Section
    //   className="py-0 overflow-hidden"
    //   data-bs-theme="light"
    //   image={bg1}
    //   position="center bottom"
    //   overlay
    // >
    //   <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
    //     <Col
    //       md={9}
    //       lg={8}
    //       xl={5}
    //       className="pb-7 pb-xl-9 text-center text-xl-start"
    //     >
    //       <h1 className="text-white fw-light">
    //         {data.heading.start}
    //         <span className="fw-bold">
    //           <Typewriter
    //             words={data.heading.words}
    //             loop={false}
    //             cursor={!isRTL ? true : false}
    //             cursorStyle="|"
    //             typeSpeed={70}
    //             deleteSpeed={50}
    //             delaySpeed={1000}
    //           />
    //         </span>
    //       </h1>
    //       <p className="lead text-white opacity-75">{data.subHeading}</p>
    //       <Button
    //         as={Link}
    //         variant="outline-light"
    //         size="lg"
    //         className="border-2 rounded-pill mt-4 fs-9 py-2"
    //         to="/trainign-nov-2024"
    //       >
    //         Check Out The Training Program
    //         <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
    //       </Button>
    //     </Col>
    //     <Col
    //       xl={{ span: 6, offset: 1 }}
    //       className="align-self-center mt-4 mt-xl-0"
    //     ></Col>
    //   </Row>
    // </Section>

    <Section
      className="py-0 overflow-hidden hero-section"
      data-bs-theme="light"
      image={bg1}
      position="center bottom"
      overlay
      style={{ minHeight: '600px' }}
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={9}
          lg={8}
          xl={5}
          className="pb-7 pb-xl-9 text-center text-md-start"
        >
          <h1 className="text-white fw-light">
            {data.heading.start}
            <span
              className="fw-bold"
              style={{
                display: 'inline-block',
                minWidth: '15ch'
              }}
            >
              <Typewriter
                words={data.heading.words}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </h1>
          <p className="lead text-white opacity-75">{data.subHeading}</p>
          {/* <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-9 py-2"
            to="/training-nov-2024"
          >
            Check Out The Training Program
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button> */}
        </Col>
        <Col
          xl={{ span: 6, offset: 1 }}
          className="align-self-center mt-4 mt-xl-0"
        ></Col>
      </Row>
    </Section>
  );
};

export default Hero;
