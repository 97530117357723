import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const routeTitles = {
      '/': 'Home - Affluence Digital Solutions',
      '/training-nov-2024': 'Training Nov 2024 - Affluence Digital Solutions',
      '/about-us': 'About Us - Affluence Digital Solutions',
      '/services': 'Our Services - Affluence Digital Solutions',
      '/contact-us': 'Contact Us - Affluence Digital Solutions',
      '/industries': 'Industries - Affluence Digital Solutions',
      '/career': 'Career - Affluence Digital Solutions',
      '/privacy-policy': 'Privacy Policy - Affluence Digital Solutions',
      '/terms-condition': 'Terms & Conditions - Affluence Digital Solutions',
      '/services/software-engineering':
        'Software Engineering - Affluence Digital Solutions',
      '/services/manual-and-automation-qa':
        'Manual & Automation QA - Affluence Digital Solutions',
      '/services/data-and-artificial-intelligence':
        'Data & Artificial Intelligence - Affluence Digital Solutions',
      '/services/mobile-app-development':
        'Mobile App Development - Affluence Digital Solutions',
      '/services/production-support':
        'Production Support - Affluence Digital Solutions',
      '/services/build-your-own-team':
        'Build Your Own Team - Affluence Digital Solutions'
    };

    // it will set title according to current route
    document.title =
      routeTitles[location.pathname] || 'Affluence Digital Solutions';
  }, [location]);
};
export default usePageTitle;
