import React from 'react';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';
import careerData from 'data/ads-data/career';

const Processes = () => {
  const process = careerData.process;
  return (
    <Section className="p-0 m-4 ">
      <SectionHeader title={process.title} subtitle={process.subTitle} />
      {isIterableArray(process.processList) &&
        process.processList.map((process, index) => (
          <Process key={index} isFirst={index === 0} {...process} />
        ))}
    </Section>
  );
};

export default Processes;
